export default {
  title: 'Promo codes',
  tabs: {
    active: 'Aktiv',
    expired: 'Ausgelaufen',
    email_sign_up: 'Promo-E-Mail-Anmeldung',
  },
  show_master_promo_code: 'Zeigen Sie den Kunden den Master-Promo-Code',
  fields: {
    code: 'Code',
    tag: 'Tag',
    type: 'Typ',
    discount_type: 'Rabattart',
    discount: 'Rabatt',
    usage_threshold: 'Mindestbestellwert',
    quantity: 'Anzahl',
    expires_at: 'Läuft ab',
    customers: 'Kunden',
    purchases: 'Bestellungen',
    sales: 'Verkauf',
    level: 'Ebene',
    max_discount: 'Maximaler Rabatt',
  },
  tags: {
    master: 'Master',
    campaign: 'Kampagne',
    one_time: 'Einmalig',
  },
  types: {
    single_use: 'Einmalige Nutzung',
    multi_use: 'Mehrfachnutzung',
    unknown: 'Unbekannt',
  },
  discount_types: {
    fixed_amount: 'Fester Betrag',
    percentage: 'Prozent %',
    unknown: 'Unbekannt',
  },
  labels: {
    discount: 'Rabatt',
    usage_threshold: 'Mindestbestellwert',
    components: 'Komponenten',
    company: 'Unternehmen',
    restaurant: 'Restaurant',
    restaurant_group: 'Restaurant Gruppe',
  },
  buttons: {
    add: 'Hinzufügen',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    restore: 'Wiederherstellen',
    add_promo_code: 'Promo Code hinzufügen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_update_show_master_promo_code: 'Die Sichtbarkeit des Master-Promo-Codes konnte nicht aktualisiert werden',
    failed_to_delete_promo_code: 'Promo Code löschen fehlgeschlagen',
    failed_to_restore_promo_code: 'Promo Code wiederherstellen fehlgeschlagen',
    promo_code_got_deleted: 'Promo Code wurde gelöscht',
    promo_code_got_restored: 'Promo Code wurde wiederhergestellt',
  },
  delete_confirmation: {
    title: 'Promo Code löschen?',
    buttons: {
      yes: 'Ja',
      no: 'Nein',
    },
  },
  restore_confirmation: {
    title: 'Promo Code wiederherstellen?',
    buttons: {
      yes: 'Ja',
      no: 'Nein',
    },
  },
};
